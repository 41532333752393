import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Title from "../common/Title";
import Grid from "../common/Grid";
import { LuVariable } from "react-icons/lu";
import Utils from "../../utils";
import { clientVariableActions } from "../../actions/clientVariable.actions";
import VariableGridFilter from "./VariableGridFilter";
import { MdEdit } from "react-icons/md";
import { CiTextAlignJustify } from "react-icons/ci";
import { Alert } from "react-bootstrap";

function VariableGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientVariable = useSelector((state) => state.clientVariable);

  const filterInitialState = {
    variableId: "",
    variableName: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  const actions = [
    {
      text: "Edit",
      url: "edit?variableId={id}",
      icon: <MdEdit className="mb-1 me-2" />,
    },
    {
      text: "Lines",
      url: "./line?variableId={id}",
      icon: <CiTextAlignJustify className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(clientVariableActions.get(queryParams));

    // Initialize filter from URL
    setFilter({
      variableId: queryParams["variableId"],
      variableName: queryParams["variableName"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handlePageClick = (pageNumber) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = pageNumber;

    refreshParams(queryParams);
  };

  const handleSortClick = (orderBy, orderDirection) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.order_by = orderBy;
    queryParams.order_direction = orderDirection;

    refreshParams(queryParams);
  };

  const handleSearch = (filter) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = 0;

    for (const property in filter) {
      if (filter[property]) queryParams[property] = filter[property];
      else delete queryParams[property];
    }

    refreshParams(queryParams);
  };

  const handleClearFilters = () => {
    handleSearch(filterInitialState);
  };

  const refreshParams = (queryParamsObj) => {
    navigate(`/variable?${Utils.objectToQueryString(queryParamsObj)}`);
  };

  let displayColumns = ["variableId", "variableName"];
  if (props.user.isSuperUser) displayColumns.push("clientName");

  const data = clientVariable.paginatedResult?.data;

  return (
    <>
      <Title text="My Variables" icon={<LuVariable className="mb-1" />} />

      <VariableGridFilter
        filter={filter}
        onSearch={handleSearch}
        onClearFilters={handleClearFilters}
      />

      {props.user.clientId ? (
        <Link to={"add"} className="btn btn-primary float-end mb-2">
          Add Variable
        </Link>
      ) : (
        <Alert variant="info">
          In order to see the Add Variable button, please select a Client from
          the top menu's Clients dropdown.
        </Alert>
      )}

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"variableId"}
        actions={actions}
        pageNumber={clientVariable.paginatedResult?.pageNumber ?? 0}
        totalPages={clientVariable.paginatedResult?.totalPages ?? 0}
        loading={clientVariable.loading}
        onPageChange={handlePageClick}
        onSort={handleSortClick}
      ></Grid>
    </>
  );
}

export default VariableGrid;
