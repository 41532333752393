import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Collapse } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function BroadcastMessageReviewGridFilter(props) {
  const [expanded, setExpanded] = useState(false);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (props.filter) setFilter(props.filter);
    const filterIsEmpty = Object.values(props.filter).every(
      (x) => x === null || x === "" || x === undefined
    );
    setExpanded(!filterIsEmpty);

    // eslint-disable-next-line
  }, [props]);

  const onKeyDown = (e) => {
    if (e.key === "Enter") props.onSearch(filter);
  };

  return (
    <div className="bg-light border p-3 mb-3 rounded" onKeyDown={onKeyDown}>
      <Collapse in={expanded}>
        <div>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Reviewer</Form.Label>
                <Form.Control
                  value={filter.reviewer ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, reviewer: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  value={filter.clientName ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, clientName: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="link"
            size="sm"
            className="text-decoration-none p-0 m-0 float-start mt-4 fw-bold"
            onClick={() => setExpanded(!expanded)}
          >
            Click here to collapse filters <FaAngleUp size={13} />
          </Button>
          <Button
            variant="primary"
            className="float-end mt-3"
            onClick={() => props.onSearch(filter)}
          >
            Search
          </Button>
          <Button
            variant="secondary"
            className="float-end mt-3 me-2"
            onClick={() => props.onClearFilters()}
          >
            Clear filters
          </Button>
          <div className="clearfix" />
        </div>
      </Collapse>
      {!expanded && (
        <Button
          variant="link"
          size="sm"
          className="text-decoration-none p-0 m-0 fw-bold"
          onClick={() => setExpanded(!expanded)}
        >
          Click here to expand filters <FaAngleDown size={13} />
        </Button>
      )}
    </div>
  );
}

export default BroadcastMessageReviewGridFilter;
