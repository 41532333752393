import React, { useEffect, useState } from "react";
import { FaDatabase } from "react-icons/fa";
import { runbookConnectorActions } from "../../actions/runbookConnector.actions";
import Title from "../common/Title";
import Grid from "../common/Grid";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import useRunbookTitle from "../../hooks/runbookTitle";
import Subtitle from "../common/Subtitle";
import { CiBoxList } from "react-icons/ci";
import ConfirmModal from "../common/ConfirmModal";
import Autocomplete from "../common/Autocomplete";
import { clientConnectorActions } from "../../actions/clientConnector.actions";
import { Button } from "react-bootstrap";
import DetailsModal from "../common/DetailsModal";

function RunbookConnectorGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const runbookConnectorReducer = useSelector(
    (state) => state.runbookConnector
  );
  const clientConnectorReducer = useSelector((state) => state.clientConnector);

  const { runbookTitle, runbookTitleLoading, clientId, clientGuid } =
    useRunbookTitle();

  const [confirmDeleteModalShow, setConfirmDeleteModalShow] = useState(false);
  const [deleteConnectorId, setDeleteConnectorId] = useState("");
  const [viewConnectorId, setViewConnectorId] = useState("");
  const runbookId = Utils.getQueryStringParams(location).runbookId;
  const [displayViewModal, setDisplayViewModal] = useState(false);

  let actions = [
    {
      text: "Remove",
      event: (id) => {
        setDeleteConnectorId(id);
        setConfirmDeleteModalShow(true);
      },
      icon: <MdDelete className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    if (clientId) {
      let queryParams = Utils.getQueryStringParams(location);
      queryParams["clientId"] = clientId;
      queryParams["clientGuid"] = clientGuid;

      dispatch(runbookConnectorActions.get(queryParams));
      dispatch(clientConnectorActions.clearSuggestions());
    }
    // eslint-disable-next-line
  }, [clientId]);

  const handlePageClick = (pageNumber) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = pageNumber;

    refreshParams(queryParams);
  };

  const handleSortClick = (orderBy, orderDirection) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.order_by = orderBy;
    queryParams.order_direction = orderDirection;

    refreshParams(queryParams);
  };

  const refreshParams = (queryParamsObj) => {
    navigate(`/runbookConnector?${Utils.objectToQueryString(queryParamsObj)}`);
  };

  const handleDeleteConnector = () => {
    setConfirmDeleteModalShow(false);
    let requestParameters = {
      connectorId: deleteConnectorId,
      runbookId: runbookId,
      clientId: clientId,
      clientGuid: clientGuid,
    };
    dispatch(runbookConnectorActions.remove(requestParameters));
  };

  const handleTextChange = (value) => {
    dispatch(
      clientConnectorActions.suggestions({
        text: value,
        clientId: clientId,
        clientGuid: clientGuid,
        max_results: 5,
      })
    );
  };

  const handleConnectorSelect = (selectedConnectorId) => {
    const requestParameters = {
      runbookId: runbookId,
      connectorId: selectedConnectorId,
      clientId: clientId,
      clientGuid: clientGuid,
    };
    dispatch(runbookConnectorActions.create(requestParameters));
    dispatch(clientConnectorActions.clearSuggestions());
  };

  const handleConnectorView = (selectedConnectorId) => {
    setViewConnectorId(selectedConnectorId);
    setDisplayViewModal(true);

    const requestParameters = {
      connectorId: selectedConnectorId,
      clientId: clientId,
      clientGuid: clientGuid,
    };
    dispatch(clientConnectorActions.get(requestParameters));
  };

  let displayColumns = ["connectorId", "description", "system"];

  const loading = runbookTitleLoading || runbookConnectorReducer.loadingGet;

  const data = runbookConnectorReducer.paginatedResult?.data.map((rb) => {
    return {
      ...rb,
      id: rb.connectorId,
      connectorId: (
        <Button
          variant="link"
          onClick={() => handleConnectorView(rb.connectorId)}
        >
          {rb.connectorId}
        </Button>
      ),
    };
  });

  const suggestions = clientConnectorReducer.suggestions?.data?.map((cc) => {
    return {
      ...cc,
      id: cc.connectorId,
      name: (
        <div>
          {cc.description} - {cc.system} ({cc.connectorId}) <br />
          <small>{cc.loginEndpoint}</small>
        </div>
      ),
    };
  });

  const {
    clientSecret,
    oAuthClientId,
    userPassword,
    senderPassword,
    ...filteredData
  } = clientConnectorReducer.paginatedResult?.data[0] || {};

  return (
    <>
      <Title text="Runbook Connectors" icon={<FaDatabase className="mb-1" />} />
      {!loading && (
        <>
          <Subtitle text={runbookTitle} icon={<CiBoxList className="mb-1" />} />

          <Autocomplete
            placeholder={`Add Connectors for ${runbookId}`}
            suggestions={suggestions}
            onSelect={handleConnectorSelect}
            onTextChange={handleTextChange}
          />
        </>
      )}

      <div className="mt-3"></div>

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"id"}
        actions={actions}
        pageNumber={runbookConnectorReducer.paginatedResult?.pageNumber ?? 0}
        totalPages={runbookConnectorReducer.paginatedResult?.totalPages ?? 0}
        loading={loading}
        onPageChange={handlePageClick}
        onSort={handleSortClick}
      />

      <ConfirmModal
        show={confirmDeleteModalShow}
        title="Remove Connector"
        question={`Are you sure you want to remove Connector ${deleteConnectorId} from Runbook ${runbookId}?`}
        noButtonText="No"
        yesButtonText="Yes, please"
        handleClose={() => {
          setConfirmDeleteModalShow(false);
        }}
        handleConfirm={handleDeleteConnector}
      />

      <DetailsModal
        show={displayViewModal}
        title={
          <>
            <FaDatabase className="mb-1 me-2" /> {viewConnectorId} Details
          </>
        }
        onClose={() => setDisplayViewModal(false)}
        loading={clientConnectorReducer.loading}
        data={filteredData}
      />
    </>
  );
}

export default RunbookConnectorGrid;
