import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Title from "../common/Title";
import Grid from "../common/Grid";
import Utils from "../../utils";
import { broadcastMessageReviewActions } from "../../actions/broadcastMessageReview.actions";
import { FaEye } from "react-icons/fa";
import moment from "moment";
import BroadcastMessageReviewGridFilter from "./BroadcastMessageReviewGridFilter";
import { GiMegaphone } from "react-icons/gi";
import Subtitle from "../common/Subtitle";

function BroadcastMessageReviewGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const broadcastMessageReview = useSelector(
    (state) => state.broadcastMessageReview
  );

  const filterInitialState = {
    reviewer: "",
    clientName: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  const actions = [];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(broadcastMessageReviewActions.get(queryParams));

    // Initialize filter from URL
    setFilter({
      reviewer: queryParams["reviewer"],
      clientName: queryParams["clientName"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handlePageClick = (pageNumber) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = pageNumber;

    refreshParams(queryParams);
  };

  const handleSortClick = (orderBy, orderDirection) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.order_by = orderBy;
    queryParams.order_direction = orderDirection;

    refreshParams(queryParams);
  };

  const handleSearch = (filter) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = 0;

    for (const property in filter) {
      if (filter[property]) queryParams[property] = filter[property];
      else delete queryParams[property];
    }

    refreshParams(queryParams);
  };

  const handleClearFilters = () => {
    handleSearch(filterInitialState);
  };

  const refreshParams = (queryParamsObj) => {
    navigate(`/messages/reviews?${Utils.objectToQueryString(queryParamsObj)}`);
  };

  let displayColumns = ["reviewer", "reviewedOn"];
  if (props.user.isSuperUser) displayColumns.push("clientName");

  const data = broadcastMessageReview.paginatedResult?.data.map((m) => {
    return {
      ...m,
      reviewedOn: moment(Utils.convertToDateTime(m.reviewedOn)).fromNow(),
    };
  });

  return (
    <>
      <Title
        text="Broadcast Messages Reviews"
        icon={<FaEye className="mb-1" />}
      />

      {!broadcastMessageReview.loading && (
        <Subtitle
          text={`Reviews of Broadcast Message '${broadcastMessageReview.paginatedResult?.data[0].messageTitle}'`}
          icon={<GiMegaphone className="mb-1" />}
        />
      )}

      <BroadcastMessageReviewGridFilter
        filter={filter}
        onSearch={handleSearch}
        onClearFilters={handleClearFilters}
      />

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"messageId"}
        actions={actions}
        pageNumber={broadcastMessageReview.paginatedResult?.pageNumber ?? 0}
        totalPages={broadcastMessageReview.paginatedResult?.totalPages ?? 0}
        loading={broadcastMessageReview.loading}
        onPageChange={handlePageClick}
        onSort={handleSortClick}
      ></Grid>
    </>
  );
}

export default BroadcastMessageReviewGrid;
